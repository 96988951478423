import { useEffect, useRef, useState, useContext, Suspense, lazy } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { MathJaxContext } from "better-react-mathjax";
// import { ParallaxProvider } from "react-scroll-parallax";

import { Provider } from "react-redux";
import store from "./redux/app/store";

import Navbar from "./components/navbar/Navbar";
// import Admindash from './components/dashboard/Admindash';
// import Dashboard from "./components/dashboard/Dashboard";
import Landingpage from "./components/landingpage/Landingpage";

// import Chatbot from "./components/chatBotX/Chatbot";
// import ChatBtn from "./components/chatBotX/ChatBtn";
import ChatBot from "./components/chatBot/ChatBot";

// import ChapterSelection from "./components/chapterSelection/ChapterSelection";
// import ChapterDetail from "./components/chapterDetail/ChapterDetail";
// import ChapterPractice from "./components/chapterPractice/ChapterPractice";
// import ChapterTrial from "./components/chapterTrial/ChapterTrial";
// import QuestionCheck from "./components/questionCheck/QuestionCheck";
// import QuestionTester from "./components/questionTester/QuestionTester";
// import ReviewMode from "./components/chapterReview/ReviewMode";

// import SetCompletion from "./components/completion/SetCompletion";
// import ChapterCompletion from "./components/completion/ChapterCompletion";
// import ReviseCompletion from "./components/completion/ReviseCompletion";
// import SignIn, { loggedInUserCheck } from "./components/signIn/SignIn";

// import ContactUs from "./page/contact/ContactUs";
// import About from "./page/about/About";
// import OurTeam from "./page/team/OurTeam";

// import Blog from "./page/blog/BlogPage";
// import BlogView from "./page/blog/BlogView";
// import Profile from "./components/profile/Profile";
// // import PremiumChapterPurchase from "./components/premiumChapterPurchase";
// import Sitemap from "./Sitemap";

// import PricingPolicy from "./page/pricing/PricingPolicy";
// import CancellationAndRefund from "./page/cancellationAndRefund/CancellationAndRefund";
// import PrivacyPolicy from "./page/privacy/PrivacyPolicy";
import TemsAndConditions from "./page/termsAndConditions/TermsAndConditions";

// import ErrorPage from "./components/errorPage/ErrorPage";
// import Preloader from "./components/ui/loading/Preloader";

import ProtectedComponent from "./components/protectedComponent/ProtectedComponent";

// import FirebaseAuthContextProvider from "./context/FirebaseAuthContext";
// import FirebaseContextProvider from "./context/FirebaseContext";
// import PopupContextProvider from "./context/PopupStatusContext";

// import useLocalUserData from "./localStorage/useLocalUserData";
// import useNioclassTutorialRecord from "./localStorage/useNioclassTutorialRecord";
// import DeviceDetector from "device-detector-js";
import CreditDebit from "./components/popup/CreditDebit";
import NotesAdded from "./components/popup/NotesAdded";
// import MyNotes from "./components/dashboard/MyNotes";

import LandingPageNav from "./components/protectedComponent/LandingPageNav";

const Dashboard = lazy(() => import("./components/dashboard/Dashboard"));
// const Landingpage = lazy(() =>
//   import("./components/landingpage/Landingpage")
// );
const ChapterSelection = lazy(() =>
  import("./components/chapterSelection/ChapterSelection")
);
const ChapterDetail = lazy(() =>
  import("./components/chapterDetail/ChapterDetail")
);
const ChapterPractice = lazy(() =>
  import("./components/chapterPractice/ChapterPractice")
);
const ChapterTrial = lazy(() =>
  import("./components/chapterTrial/ChapterTrial")
);
const QuestionCheck = lazy(() =>
  import("./components/questionCheck/QuestionCheck")
);
const QuestionTester = lazy(() =>
  import("./components/questionTester/QuestionTester")
);
const ReviewMode = lazy(() => import("./components/chapterReview/ReviewMode"));
const SetCompletion = lazy(() =>
  import("./components/completion/SetCompletion")
);
const ChapterCompletion = lazy(() =>
  import("./components/completion/ChapterCompletion")
);
const ReviseCompletion = lazy(() =>
  import("./components/completion/ReviseCompletion")
);
const SignIn = lazy(() => import("./components/signIn/SignIn"));
const ContactUs = lazy(() => import("./page/contact/ContactUs"));
const About = lazy(() => import("./page/about/About"));
const OurTeam = lazy(() => import("./page/team/OurTeam"));
const Blog = lazy(() => import("./page/blog/BlogPage"));
const BlogView = lazy(() => import("./page/blog/BlogView"));
const Profile = lazy(() => import("./components/profile/Profile"));
const Sitemap = lazy(() => import("./Sitemap"));
const PricingPolicy = lazy(() => import("./page/pricing/PricingPolicy"));
const CancellationAndRefund = lazy(() =>
  import("./page/cancellationAndRefund/CancellationAndRefund")
);
const PrivacyPolicy = lazy(() => import("./page/privacy/PrivacyPolicy"));
const TermsAndConditions = lazy(() =>
  import("./page/termsAndConditions/TermsAndConditions")
);
const ErrorPage = lazy(() => import("./components/errorPage/ErrorPage"));
const ActivityRecord = lazy(() =>
  import("./components/dashboard/ActivityRecord")
);
const VideoCom = lazy(() => import("./components/videoCom/VideoCom"));
const TimerTest = lazy(() =>
  import("./components/testSeries/timerTest/TimerTest")
);
const EndTest = lazy(() => import("./components/testSeries/EndTest"));
const TimerTestSolutions = lazy(() =>
  import("./components/testSeries/testSolution/TimerTestSolutions")
);
const AttemptedTest = lazy(() =>
  import("./components/testSeries/attemptedTest/AttemptedTest")
);
const GuidedTest = lazy(() =>
  import("./components/testSeries/guidedTest/GuidedTest")
);
const TestStarting = lazy(() => import("./components/testSeries/TestStarting"));

const QuickTest = lazy(() => import("./components/QuickTest/QuickTest"));
const FreeTest = lazy(() => import("./components/QuickTest/FreeTest"));
const OptionValuesTester = lazy(() =>
  import("./components/questionTester/OptionTester/OptionValues")
);
const CommingSoon = lazy(() => import("./page/commingSoon/CommingSoon"));
const WatchVideo = lazy(() => import("./components/watchVideo/WatchVideo"));
const VideoPlayer = lazy(() => import("./components/watchVideo/VideoPlayer"));
const ChapterDetailSets = lazy(() =>
  import("./components/chapterDetail/ChapterDetailsSets")
);
const MyNotes = lazy(() => import("./components/dashboard/MyNotes"));
import "./App.css";
import axios from "axios";
import useLocalUserData from "./localStorage/useLocalUserData";
import useNioclassTutorialRecord from "./localStorage/useNioclassTutorialRecord";
import FirebaseContextProvider from "./context/FirebaseContext";
import FirebaseAuthContextProvider from "./context/FirebaseAuthContext";
import PopupContextProvider from "./context/PopupStatusContext";
import { loggedInUserCheck } from "./components/signIn/SignIn";
import examSaturation from "./components/apis/apiFunctions/examSaturation";
import Preloader from "./components/ui/loading/Preloader";
import { ParallaxProvider } from "react-scroll-parallax";
import PracticeHub from "./components/testSeries/practiceHub";
import ReactPixel from "react-facebook-pixel";
import RedditPixel from "react-reddit-pixel";
// import PaymentSuccessOption from "./components/QuickTest/PaymentSuccessOption";
const PaymentProcessModal = lazy(() =>
  import("./components/QuickTest/PaymentProcessModal")
);
const PaymentSuccessOption = lazy(() =>
  import("./components/QuickTest/PaymentSuccessOption")
);
// import BackDrop from "./components/ui/backdrop/BackDrop";

let examSaturationData = [
  {
    landingpage: "unified",
    org: "NioClass",
    ChapterTh: "_Theory_IIT",
    Chapter: "_IIT",
    SlugCh: "IIT",
    DropSubjects: ["Maths", "Chemistry", "Physics"],
    DropSubjectsImages: [
      "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Maths+Icon.png",
      "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Chemistry+Icon.png",
      "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Physics+Icon.png",
    ],
    logo: "nio",
    isPurchased: false,
    navbar: [
      "chapters",
      "dashboard",
      "testSeries",
      "mymemory",
      "mynotes",
      "profilebtn",
    ],
    customTestSubjects: ["Maths", "Physics", "Chemistry"],
  },
  {
    landingpage: "unified",
    org: "NioClass",
    ChapterTh: "_Theory_IIT",
    Chapter: "_IIT",
    SlugCh: "SSC",
    DropSubjects: ["Biology", "Chemistry", "Physics"],
    DropSubjectsImages: [
      "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Maths+Icon.png",
      "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Chemistry+Icon.png",
      "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Physics+Icon.png",
    ],
    logo: "nio",
    isPurchased: false,
    navbar: ["testSeries"],
    customTestSubjects: ["English", "GeneralKnowledge", "Maths", "Reasoning"],
    customTestSubjectsName: [
      "English Comprehension",
      "General Knowledge",
      "Quantitative Aptitude",
      "Reasoning",
    ],
  },

  {
    landingpage: "unified",
    org: "CL",
    ChapterTh: "_Theory_CAT",
    Chapter: "_CAT",
    SlugCh: "CAT",
    DropSubjects: ["Quant"],
    DropSubjectsImages: [
      "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Maths+Icon.png",
      "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Chemistry+Icon.png",
      "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Physics+Icon.png",
    ],
    logo: "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/logo-black+(1).png",
    isPurchased: true,
    navbar: ["dashboard", "chapters", "exitbtn"],
    customTestSubjects: ["Maths", "Physics", "Chemistry"],
  },

  {
    landingpage: "unified",
    org: "NioClass",
    ChapterTh: "_Theory_CAT",
    Chapter: "_CAT",
    SlugCh: "CAT",
    DropSubjects: ["Quant", "Logical_Reasoning", "Data_Interpretation"],
    DropSubjectsImages: [
      "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Maths+Icon.png",
      "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Chemistry+Icon.png",
      "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Physics+Icon.png",
    ],
    logo: "nio",
    isPurchased: false,
    navbar: [
      "chapters",
      "dashboard",
      "mymemory",
      "mynotes",
      "profilebtn",
      "testSeries",
    ],
    customTestSubjects: ["Maths", "Physics", "Chemistry"],
  },
  {
    landingpage: "unified",
    org: "NioClass",
    ChapterTh: "_Theory_IIT",
    Chapter: "_IIT",
    SlugCh: "RRB",
    DropSubjects: ["Biology", "Chemistry", "Physics"],
    DropSubjectsImages: [
      "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Maths+Icon.png",
      "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Chemistry+Icon.png",
      "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Physics+Icon.png",
    ],
    logo: "nio",
    isPurchased: false,
    navbar: ["testSeries", "contactus", "logout"],
    customTestSubjects: ["Maths", "Reasoning", "Awareness"],
    customTestSubjectsName: [
      "Mathematics",
      "General Intelligence and Reasoning",
      "General Awareness",
    ],
  },

  {
    landingpage: "unified",
    org: "NioClass",
    ChapterTh: "_Theory_IIT",
    Chapter: "_IIT",
    SlugCh: "IBPS",
    DropSubjects: ["Biology", "Chemistry", "Physics"],
    DropSubjectsImages: [
      "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Maths+Icon.png",
      "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Chemistry+Icon.png",
      "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Physics+Icon.png",
    ],
    logo: "nio",
    isPurchased: false,
    navbar: ["testSeries", "contactus", "logout"],
    customTestSubjects: ["English", "Maths", "Reasoning"],
    customTestSubjectsName: [
      "English Language",
      "Quantitative Aptitude",
      "Reasoning Ability",
    ],
  },
  {
    landingpage: "unified",
    org: "NioClass",
    ChapterTh: "_Theory_GATE",
    Chapter: "_GATE",
    SlugCh: "GATE",
    DropSubjects: ["Maths", "CSE", "CE", "EE", "ME"],
    DropSubjectsImages: [
      "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Maths+Icon.png",
      "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Chemistry+Icon.png",
      "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Physics+Icon.png",
    ],
    logo: "nio",
    isPurchased: false,
    navbar: ["testSeries", "practiceHub", "contactus", "logout"],
    customTestSubjects: [
      "EngineeringMathematics_GATE",
      "DigitalLogic_GATE",
      "ComputerOrganizationAndArchitecture_GATE",
      "DataStructureAndAlgorithms_GATE",
      "OperatingSystems_GATE",
      "DBMS_GATE",
      "ComputerNetworks_GATE",
      "TheoryofComputation_GATE",
      "CompilerDesign_GATE",
      "GeneralAptitude_GATE",
    ],
  },
];

function App() {
  // const deviceDetector = new DeviceDetector();
  // const userAgent = navigator.userAgent;
  // const device = deviceDetector.parse(userAgent);

  // console.log(device)

  // if (device.device.type === "smartphone") {
  //   // console.log("Device is smart phone")
  //   // window.open("https://m.niopractice.com", "_self")
  // }
  // useEffect(() => {
  //   const referringURL = document?.referrer;
  //   console.log("Referring URL:", referringURL);
  //   // Process referring URL as needed
  // }, [document?.referrer]);

  //need some point of differentiation between student and teacher role
  // const studentRole = false;
  const startingAnimation = useRef();

  const { createAnonymousLocalUserData } = useLocalUserData();
  const { setTutorialRecord } = useNioclassTutorialRecord();

  const [user, setUser] = useState();
  useEffect(() => {
    if(window?.location?.host?.includes('gate')){
    // Initialize Facebook Pixel
    ReactPixel.init("398625398945471"); // Replace with your actual Pixel ID
    ReactPixel.pageView(); // Automatically tracks PageView event
    const options = {
      debug: false, // set true to enable logs
    };
    RedditPixel.init("a2_g3rra14yyqoc", options);
RedditPixel.pageVisit(); // For tracking page visit
  }
  }, []);
  const [loaded, setLoaded] = useState(true);
  const [subjectualData, setAllSubjects] = useState(
    window.location.host?.toLowerCase()?.includes("ssc")
      ? examSaturationData[1]
      : window.location.host?.toLowerCase()?.includes("careerlauncher")
      ? examSaturationData[2]
      : window.location.host?.toLowerCase()?.includes("cat")
      ? examSaturationData[3]
      : window.location.host?.toLowerCase()?.includes("ibps")
      ? examSaturationData[5]
      : window.location.host?.toLowerCase()?.includes("rrb")
      ? examSaturationData[4]
      : window.location.host?.toLowerCase()?.includes("gate")
      ? examSaturationData[6]
      : examSaturationData[0]
  );
  // console.log(user);
  const [openChatbot, setOpenChatbot] = useState(false);
  const [bounceAnimation, setBounceAnimation] = useState(false);
  const [utmCompaignD, setUtmCompaignD] = useState(null);
  const [switches, setSwitches] = useState(false);
  const [firstlogin, setFirstlogin] = useState(true);
  // const [navbar, setNavbar] = useState(true);

  // const [creditDebited, setcreditDebited] = useState(false);

  const config = {
    tex: {
      inlineMath: [
        ["$", "$"],
        ["\\(", "\\)"],
      ],
    },
  };

  useEffect(() => {
    // setTimeout(() => {
    //   setLoaded(true);
    // }, [1000]);
    createAnonymousLocalUserData();
    setTutorialRecord();
  }, []);

  useEffect(() => {
    function getQueryParam(param) {
      let url = new URL(window.location.href);
      // console.log(url.searchParams);
      return url.searchParams.get(param);
    }
    // Fetch the value of the utm_campaign parameter
    let utmCampaign = getQueryParam("utm_campaign");
    // Log the value to the console
    if (utmCampaign?.length) {
      localStorage.setItem("UtmCampaign", utmCampaign);
    }

    setUtmCompaignD(utmCampaign);
  }, []);

  // alert("There is some outage with the website... It will be resolved soon");
  useEffect(() => {
    examSaturation().then((res) => {
      console.log(res.data);
      let adata = res.data;
      setAllSubjects(adata);
    });
  }, [user]);

  useEffect(() => {
    if (user && user !== '"undefined"') {
      axios.get(
        `https://1edof059ii.execute-api.ap-northeast-1.amazonaws.com/setUserName/setusername?UserID=${user}&VisitsCount=KunalBurIzIOMON`
      );
    }
  }, [user]);
  //   useEffect(() => {
  //  if(((window.location?.pathname?.length > 1) || window.location.host?.includes("nio") || window.location.host?.includes("local")))
  //     {   setNavbar(true)}
  //   }, [window.location.pathname]);

  const fullScreenRef = useRef(null);
  // const fullScreenBtn = useRef(null);

  const enterFullScreen = () => {
    const elem = fullScreenRef.current;

    // if (elem.requestFullscreen) {
    //   elem.requestFullscreen();
    // } else
    if (elem?.mozRequestFullScreen) {
      /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem?.webkitRequestFullscreen) {
      /* Chrome, Safari & Opera */
      elem.webkitRequestFullscreen();
    } else if (elem?.msRequestFullscreen) {
      /* IE/Edge */
      elem.msRequestFullscreen();
    }
  };

  const exitFullScreen = () => {
    // if (document.exitFullscreen) {
    //   document.exitFullscreen();
    // } else
    if (document.mozCancelFullScreen) {
      /* Firefox */
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      /* Chrome, Safari & Opera */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      /* IE/Edge */
      document.msExitFullscreen();
    }
  };

  const adjustStylesOnFullScreen = (enteringFullScreen) => {
    const elem = fullScreenRef.current;

    if (elem) {
      if (enteringFullScreen) {
        elem.style.overflow = "auto"; // Enable scrolling
        elem.style.height = "100vh"; // Set height to viewport height
      } else {
        elem.style.overflow = "visible"; // Reset overflow
        elem.style.height = "auto"; // Reset height
      }
    }
  };

  const handleFullScreenChange = () => {
    const isFullScreen =
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement;

    adjustStylesOnFullScreen(isFullScreen);
  };

  // Event listeners for fullscreen change
  useEffect(() => {
    if (switches) {
      document.addEventListener("fullscreenchange", handleFullScreenChange);
      document.addEventListener(
        "webkitfullscreenchange",
        handleFullScreenChange
      );
      document.addEventListener("mozfullscreenchange", handleFullScreenChange);
      document.addEventListener("MSFullscreenChange", handleFullScreenChange);

      return () => {
        document.removeEventListener(
          "fullscreenchange",
          handleFullScreenChange
        );
        document.removeEventListener(
          "webkitfullscreenchange",
          handleFullScreenChange
        );
        document.removeEventListener(
          "mozfullscreenchange",
          handleFullScreenChange
        );
        document.removeEventListener(
          "MSFullscreenChange",
          handleFullScreenChange
        );
      };
    }
  }, [switches]);
  // if (!loaded)
  //   return (
  //     <div className="app">
  //       {" "}
  //       <Preloader />
  //     </div>
  //   );
  // console.log(window.location?.pathname > 1);
  return (
    <div className="app" ref={fullScreenRef}>
      <Provider store={store}>
        {/* {!loaded && <Preloader />} */}
        <ParallaxProvider>
          <FirebaseContextProvider>
            <FirebaseAuthContextProvider>
              <PopupContextProvider>
                <Router>
                  {/* {!loaded ? ( */}
                  <Navbar
                    startingAnimation={startingAnimation}
                    setBounceAnimation={setBounceAnimation}
                    bounceAnimation={bounceAnimation}
                    user={user}
                    setUser={setUser}
                    loaded={loaded}
                    setLoaded={setLoaded}
                    subjectualData={subjectualData}
                    utmCompaignD={utmCompaignD}
                    setSwitches={setSwitches}
                  />
                  <Routes>
                    <Route
                      path="/website"
                      element={
                        <Landingpage
                          utmCompaignD={utmCompaignD}
                          user={user}
                          setUser={setUser}
                          subjectualData={subjectualData}
                          setFirstlogin={setFirstlogin}
                          firstlogin={firstlogin}
                        />
                      }
                    />
                  </Routes>
                  {/* <Suspense fallback={<div>Loading...</div>}> */}

                  {/* </Suspense> */}

                  {/* // ) : null} */}
                  {/* {openChatbot && <Chatbot setOpenChatbot={setOpenChatbot} />} */}

                  <MathJaxContext config={config}>
                    <Suspense fallback={<div>Loading...</div>}>
                      <Routes>
                        {!window?.location?.host
                          ?.toLowerCase()
                          ?.includes("careerlauncher") ? (
                          <Route
                            path="/"
                            element={
                              <LandingPageNav
                                utmCompaignD={utmCompaignD}
                                user={user}
                                setUser={setUser}
                                subjectualData={subjectualData}
                                component={
                                  subjectualData?.navbar?.includes(
                                    "dashboard"
                                  ) && loggedInUserCheck(user)
                                    ? Dashboard
                                    : subjectualData?.navbar?.includes(
                                        "testSeries"
                                      )
                                    ? "testSeries/custom-test/timertest"
                                    : subjectualData?.navbar[0]
                                }
                              />
                            }
                          />
                        ) : null}
                        {!window?.location?.host
                          ?.toLowerCase()
                          ?.includes("careerlauncher") ? (
                          <Route path="/website" element={<></>} />
                        ) : null}
                        <Route path="/watchVideo" element={<WatchVideo />} />
                        <Route path="/videoPlayer" element={<VideoPlayer />} />
                        <Route
                          path="/sign-in"
                          element={
                            <SignIn
                              utmCompaignD={utmCompaignD}
                              setUser={setUser}
                              subjectualData={subjectualData}
                            />
                          }
                        />
                        <Route
                          path="/sign-in/:user"
                          element={
                            <SignIn
                              utmCompaignD={utmCompaignD}
                              setUser={setUser}
                              subjectualData={subjectualData}
                            />
                          }
                        />
                        <Route
                          path="/chapter-selection"
                          element={
                            <ChapterSelection
                              setUser={setUser}
                              subjectualData={subjectualData}
                              setLoaded={setLoaded}
                              enterFullScreen={enterFullScreen}
                            />
                          }
                        />
                        <Route
                          path="/ChapterDetailSets"
                          element={
                            <ChapterDetailSets
                              user={user}
                              subjectualData={subjectualData}
                              // setUser={setUser}
                              // subjectualData={subjectualData}
                              // setLoaded={setLoaded}
                              // enterFullScreen={enterFullScreen}
                            />
                          }
                        />
                        <Route
                          path="/chapter-selection/:token"
                          element={
                            <ChapterSelection
                              setUser={setUser}
                              subjectualData={subjectualData}
                              setLoaded={setLoaded}
                              enterFullScreen={enterFullScreen}
                            />
                          }
                        />

                        <Route path="/tutorialVid" element={<VideoCom />} />

                        <Route
                          path="/chapter-trial"
                          element={<ChapterTrial />}
                        />
                        <Route
                          path="/chapter-detail"
                          element={<ChapterDetail />}
                        />

                        {/* protected routes */}
                        <Route
                          path="dashboard"
                          element={
                            <Dashboard
                              user={user}
                              setUser={setUser}
                              subjectualData={subjectualData}
                            />
                          }
                        />
                        <Route
                          path="dashboard/:token"
                          element={
                            <Dashboard
                              user={user}
                              setUser={setUser}
                              subjectualData={subjectualData}
                            />
                          }
                        />
                        <Route
                          path="activity-record"
                          element={
                            <ProtectedComponent
                              user={user}
                              subjectualData={subjectualData}
                              component={ActivityRecord}
                            />
                          }
                        />
                        <Route
                          path="myNotes"
                          element={
                            <ProtectedComponent
                              user={user}
                              subjectualData={subjectualData}
                              component={MyNotes}
                            />
                          }
                        />

                        {/* <Route path="admin" element={<ProtectedComponent component={Admindash} />} /> */}
                        {/* <Route path="dashboard" element={studentRole ? <ProtectedComponent component={Dashboard} /> : <ProtectedComponent component={Admindash} />} /> */}
                        <Route
                          path="my-profile"
                          element={
                            <ProtectedComponent
                              user={user}
                              setUser={setUser}
                              subjectualData={subjectualData}
                              component={Profile}
                            />
                          }
                        />
                        {/* <Route path="chapter-practice" element={<ProtectedComponent subjectualData={subjectualData} startingAnimation={startingAnimation} setBounceAnimation={setBounceAnimation} component={ChapterPractice} />} /> */}
                        <Route
                          path="chapter-practice"
                          element={
                            <ChapterPractice
                              setLoaded={setLoaded}
                              setUser={setUser}
                              subjectualData={subjectualData}
                              startingAnimation={startingAnimation}
                              setBounceAnimation={setBounceAnimation}
                            />
                          }
                        />
                        {/* <Route path="chapter-practice/:token" element={<ProtectedComponent subjectualData={subjectualData} startingAnimation={startingAnimation} setBounceAnimation={setBounceAnimation} component={ChapterPractice} />} /> */}
                        <Route
                          path="chapter-practice/:token"
                          element={
                            <ChapterPractice
                              setLoaded={setLoaded}
                              setUser={setUser}
                              subjectualData={subjectualData}
                              startingAnimation={startingAnimation}
                              setBounceAnimation={setBounceAnimation}
                            />
                          }
                        />
                        <Route
                          path="review-mode"
                          element={
                            <ProtectedComponent
                              user={user}
                              subjectualData={subjectualData}
                              component={ReviewMode}
                            />
                          }
                        />

                        {/* <Route path="/p" element={<PremiumChapterPurchase />} /> */}

                        {/* completion routing */}
                        <Route
                          path="/deck-completion"
                          element={<SetCompletion />}
                        />
                        <Route
                          path="/chapter-completion"
                          element={<ChapterCompletion />}
                        />
                        <Route
                          path="/review-completion"
                          element={<ReviseCompletion user={user} />}
                        />

                        {/* QuestionTesterRoutes */}
                        <Route
                          path="/question-tester"
                          element={<QuestionTester />}
                        />
                        <Route
                          path="/option-tester"
                          element={<OptionValuesTester />}
                        />
                        <Route
                          path="/question-check"
                          element={<QuestionCheck />}
                        />

                        {/* Test Series Routing */}

                        <Route
                          path="TimerTest"
                          element={
                            <TimerTest
                              subjectualData={subjectualData}
                              exitFullScreen={exitFullScreen}
                              enterFullScreen={enterFullScreen}
                            />
                          }
                        />
                        <Route
                          path="/practiceHub"
                          element={
                            <PracticeHub
                              exitFullScreen={exitFullScreen}
                              subjectualData={subjectualData}
                              setUser={setUser}
                              UserID={user}
                              enterFullScreen={enterFullScreen}
                            />
                          }
                        />
                        {/* <Route path="submitTest" element={<EndTest />} /> */}
                        <Route
                          path="submitTest"
                          element={
                            <EndTest
                              subjectualData={subjectualData}
                              utmCompaignD={utmCompaignD}
                            />
                          }
                        />
                        <Route
                          path="TestSolutions"
                          element={
                            <TimerTestSolutions
                              subjectualData={subjectualData}
                            />
                          }
                        />
                        <Route
                          path="attemptedTest"
                          element={
                            <AttemptedTest
                              userid={user}
                              subjectualData={subjectualData}
                            />
                          }
                        />
                        <Route path="GuidedTest" element={<GuidedTest />} />

                        <Route
                          path="testSeries"
                          element={
                            <TestStarting
                              exitFullScreen={exitFullScreen}
                              subjectualData={subjectualData}
                              setUser={setUser}
                              enterFullScreen={enterFullScreen}
                            />
                          }
                        />
                        <Route
                          path="testSeries/custom-test/:type"
                          element={
                            <TestStarting
                              exitFullScreen={exitFullScreen}
                              subjectualData={subjectualData}
                              setUser={setUser}
                              enterFullScreen={enterFullScreen}
                            />
                          }
                        />
                        <Route
                          path="testSeries/custom-test"
                          element={
                            <TestStarting
                              exitFullScreen={exitFullScreen}
                              subjectualData={subjectualData}
                              setUser={setUser}
                              enterFullScreen={enterFullScreen}
                            />
                          }
                        />
                        <Route
                          path="testSeries/pyq-test"
                          element={
                            <TestStarting
                              exitFullScreen={exitFullScreen}
                              subjectualData={subjectualData}
                              setUser={setUser}
                              enterFullScreen={enterFullScreen}
                            />
                          }
                        />

                        <Route
                          path="testSeries/:token"
                          element={
                            <TestStarting
                              subjectualData={subjectualData}
                              setUser={setUser}
                              enterFullScreen={enterFullScreen}
                            />
                          }
                        />
                        <Route
                          path="QuickTest/:TestID"
                          element={<QuickTest utmCompaignD={utmCompaignD} />}
                        />
                        <Route
                          path="freeTest/:TestID"
                          element={<FreeTest utmCompaignD={utmCompaignD} />}
                        />
                        <Route
                          path="paymentTestDetails"
                          element={
                            <PaymentProcessModal
                              user={user}
                              utmCompaignD={utmCompaignD}
                            />
                          }
                        />
                        <Route
                          path="paymentSuccessTest"
                          element={
                            <PaymentSuccessOption
                              user={user}
                              utmCompaignD={utmCompaignD}
                            />
                          }
                        />

                        {/* miscellaneous routing */}
                        <Route path="/about-us" element={<About />} />
                        <Route path="/contact-us" element={<ContactUs />} />
                        <Route path="/our-team" element={<About />} />
                        <Route
                          path="/terms-and-conditions"
                          element={<TemsAndConditions />}
                        />
                        <Route
                          path="/cancellation-and-refund-policy"
                          element={<CancellationAndRefund />}
                        />
                        <Route
                          path="/pricing-policy"
                          element={<PricingPolicy />}
                        />
                        <Route
                          path="/privacy-policy"
                          element={<PrivacyPolicy />}
                        />
                        <Route path="/commingSoon" element={<CommingSoon />} />

                        <Route path="/about-us" element={<About />} />
                        <Route path="/faq" element={<About />} />
                        <Route path="/sitemap" element={<Sitemap />} />
                        <Route path="/blog" element={<Blog />} />
                        <Route path="/blog/:blogId" element={<BlogView />} />

                        {/* <Route path="/about-us" element={<OurTeam />} /> */}

                        <Route path="/tutorial" element={<OurTeam />} />
                        <Route path="/chatbot" element={<ChatBot />} />

                        <Route path="*" element={<ErrorPage />} />
                      </Routes>
                    </Suspense>
                  </MathJaxContext>

                  {/* <ChatBtn setOpenChatbot={setOpenChatbot} /> */}
                </Router>

                <NotesAdded />
                <CreditDebit />
                {/* dsfjdsfkldsf */}
                <ToastContainer
                  position="top-right"
                  autoClose={5000}
                  hideProgressBar={true}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                />
              </PopupContextProvider>
            </FirebaseAuthContextProvider>
          </FirebaseContextProvider>
        </ParallaxProvider>
      </Provider>
    </div>
  );
}
export default App;
