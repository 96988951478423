import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { signInWithPopup, FacebookAuthProvider } from "firebase/auth";

import useLocalUserData from "../../localStorage/useLocalUserData";
import useCurrentUser from "../../redux/control/useCurrentUser";

import {
  authUser,
  fbProvider,
  googleProvider,
} from "../../context/FirebaseContext";

import createUser from "./createUser";
import checkUserExist from "./checkUserExists";
import checkUserExistReturn from "./checkUserExistreturn";
import { fbIcon, googleIcon } from "../../assets/icons";
import axios from "axios";
import { getAnalytics, logEvent } from "firebase/analytics";
import ReactPixel from "react-facebook-pixel";
import RedditPixel from "react-reddit-pixel";

const QuoraPixel = () => {
  useEffect(() => {
    // Replace 'YOUR_PIXEL_ID' with your actual Quora Pixel ID
    // window.qp = window.qp || [];
    // console.log(window.qp)
    // window?.qp?.push(['init', '9da8ea9ce9b04155bf7b5baa6d9eb989']);
    // window.qp.push(['track', 'PageView']); // Track page views

    // Function to track custom events
    const trackEvent = (eventName) => {
      window.qp.push(["track", eventName]);
    };

    // Example of tracking a signup success event
    const handleSignupSuccess = () => {
      trackEvent("signup_success"); // Track signup success event
    };

    // Call this function when a signup is successful
    // For example, you might call this in your signup form submission handler
  }, []);

  return null; // This component does not render anything
};

function QuickSignUp({
  referredBy,
  phoneNumber,
  setUser,
  utmCompaignD,
  subjectualData,
}) {
  // console.log(phoneNumber)

  const navigate = useNavigate();

  const { setCurrentUser } = useCurrentUser();
  const { createLocalUserData } = useLocalUserData();

  const handleFacebookLogin = () => {
    signInWithPopup(authUser, fbProvider)
      .then((res) => {
        // console.log("RES: ", res);
        //unClear abhi
        setUserName(res.user);
        const credential = FacebookAuthProvider.credentialFromResult(res);
        const accessToken = credential.accessToken;
      })
      .catch((err) => {
        console.log("Fb error: ", err);
      });
  };
  async function updateCreditPoints(referalbyUserId) {
    try {
      await axios.get(
        "https://lkbe8ay3y7.execute-api.ap-northeast-1.amazonaws.com/updateUserCredits/updateusercredits?UserID=" +
          referalbyUserId +
          "&CreditPoints=1",
        {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        }
      );
    } catch (e) {
      console.log("caught err" + e.message);
    }
  }
  function checkUserExistReturnss(referalbyUserId) {
    try {
      axios
        .get(
          `https://uhuu9h6pef.execute-api.ap-northeast-1.amazonaws.com/getUserProfileDetails/getuserprofiledetails?UserID=${referalbyUserId}&abc=abc`,
          {
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
          }
        )
        .then((resp) => {
          let data = resp.data;
          // console.log(resp.data)
          setCurrentUser(data[0]);

          //add user credential to Local Storage - data[0]
          createLocalUserData(data[0]);

          return data?.length > 0 ? true : false;
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (e) {
      console.log("caught err" + e.message);
    }
  }
  useEffect(() => {
    checkUserExistReturnss(referredBy);
  }, [referredBy]);
  // console.log(userExistsAlready)
  const handleGoogleLogin = () => {
    signInWithPopup(authUser, googleProvider)
      .then(async (res) => {
        // console.log(res);
        // setUserName(res.user.displayName);
        // setUserId(res.user.email);
        console.log(res.user.email);
        const userExists = await checkUserExistReturn(
          '"' + res.user.email + '"',
          setCurrentUser,
          createLocalUserData
        );
        // const userExists = checkUserExistReturnss(('"' + res.user.email + '"'));
        // console.log(await checkUserExistReturn(res.user.email, setCurrentUser, createLocalUserData))
        // const userExists = await checkUserExist(res.user.displayName, res.user.email);
        // console.log(await checkUserExist(res.user.email, setCurrentUser, createLocalUserData))
        // console.log(userExists)
        // console.log(userExists, userExistsAlready, referredBy)
        // console.log(userExists)

        // testerrrrr
        try {
          if (referredBy) {
            if (!userExists) {
              updateCreditPoints(referredBy);
            }
          }
          setUser('"' + res.user.email + '"');
          if (localStorage.getItem("UserID")) {
            axios
              .get(
                "https://yec5cxedkh.execute-api.ap-northeast-1.amazonaws.com/updateUserIDwithTables?NewUserID=" +
                  '"' +
                  res.user.email +
                  '"' +
                  "&OldUserID=" +
                  localStorage.getItem("UserID")
              )
              .then((res) => {})
              .catch((err) => console.log(err));
          }

          if (!userExists) {
            // console.log("inner");

            createUser(
              res.user.displayName,
              '"' + res.user.email + '"',
              phoneNumber,
              referredBy,
              localStorage.getItem("UtmCampaign")?.length > 2
                ? localStorage.getItem("UtmCampaign")
                : utmCompaignD
            )
              .then(() => {
                const analytics = getAnalytics();
                if (window?.location?.host?.includes("gate")) {
                  logEvent(analytics, "signup_success", {
                    method: "email", // Optional: Add additional parameters if needed
                  });
                  logEvent(analytics, "signup_success_quora", {
                    method: "email", // Optional: Add additional parameters if needed
                  });
                }
                // if (window.reddit) {
                //   window.reddit("track", "signup_success", {
                //     event_name: "signup_success",
                //     additional_info: "signup_success",
                //   });
                // }
                // if (window.rdt) {
                //   window.rdt('track', 'signup_success'); // Track signup success event
                // }
                // if (window.quora) {
                //   window.quora.push(['track', 'PageView']);
                //   window.quora.push(['track', 'signup_success', { customProperty: 'signup_success' }]);

                // }

                // window.qp.push(['track', 'signup_success']);
                let user_id = '"' + res.user.email + '"';
                if (window?.location?.host?.includes("gate")) {
                  ReactPixel.track("signup_success", {
                    method: "signup_success",
                  });
                }
                // RedditPixel.track("signup_success");

                if (window.localStorage?.getItem("navigationNavbar")) {
                  navigate(window.localStorage?.getItem("navigationNavbar"), {
                    state: { UserID: localStorage.getItem("UserID") },
                  });
                  window.localStorage?.removeItem("navigationNavbar");
                } else if (
                  window.location.pathname.includes("website") ||
                  location.pathname.includes("contact-us") ||
                  location.pathname.includes("practiceHub")
                ) {
                  if (subjectualData?.navbar?.includes("chapters")) {
                    navigate(
                      "/chapter-selection" +
                        "?&" +
                        subjectualData?.DropSubjects[0] +
                        "&Test=yes",
                      { state: { UserID: user_id } }
                    );
                  } else {
                    navigate(
                      `/${
                        subjectualData?.navbar?.includes("testSeries")
                          ? subjectualData?.SlugCh?.includes("SRLR")
                            ? "testSeries/pyq-test"
                            : "testSeries/custom-test/timertest"
                          : subjectualData?.navbar[0]
                      }`,
                      {
                        state: { UserID: user_id },
                      }
                    );
                  }
                  localStorage.setItem("UserID", '"' + res.user.email + '"');
                }
              })
              .catch((err) => alert("please login after sometime"));
          } else {
            // navigate("/dashboard");
            localStorage.setItem("UserID", '"' + res.user.email + '"');
          }
          // localStorage.setItem("UserID", '"' + res.user.email + '"');
          // window.reload()
        } catch {
          alert("Something went wrong, Please try Again...");
        }

        //ends
        // console.log("outerrrr")
        // navigate('/dashboard');
      })
      .catch((err) => {
        console.log("google error: ", err);
      });
  };

  return (
    <div className="sign-up-quick">
      {/* <b></b> */}
      {/* <QuoraPixel /> */}
      <div className="emptyDivSign_in_height" />

      <div className="quick-sign-up-otps">
        <div
          className="sign-up-google sign-up-btn googleLoginStrokeBorder"
          style={{ boxShadow: "0px 0px 5px 1px" }}
        >
          <button onClick={handleGoogleLogin}>
            <img src={googleIcon} alt="google" />
            <div className="contWithGoogleStep">Continue with Google</div>
          </button>
        </div>
        {/* <div className="sign-up-fb sign-up-btn">
                    <button onClick={handleFacebookLogin}>
                        <img src={fbIcon} alt="facebook" />
                        Facebook
                    </button>
                </div> */}
      </div>
    </div>
  );
}

export default QuickSignUp;
