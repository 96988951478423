import PropTypes from "prop-types";
import "./Section1.css";
import { useNavigate } from "react-router-dom";

const Section1 = ({ className = "" }) => {
  const navigation = useNavigate();

  const handleClickBtn = () => {
    navigation("/testSeries/pyq-test");
  };

  return (
    <div className={`pyq-and-mocks ${className}`}>
      <div className="pyq-mock-tests-image">
        <img
          className="pyq-mock-tests-image1"
          alt="PYQ test"
          loading="lazy"
          src={
            window.location.host?.toLowerCase()?.includes("ssc")
              ? "/pyqmock-tests-image@2x.png"
              : window.location.host?.toLowerCase()?.includes("ibps")
              ? "/IBPS_Pyq_mock.png"
              : window.location.host?.toLowerCase()?.includes("gate")
              ? "/gate_Pyq_mock.png"
              : "/RRB_pyq_mock.png"
          }
        />
      </div>
      <div className="pyqs-mocks-features">
        <div className="heading1">
          <img className="frame-icon" alt="" src="/frame.svg" />
          <b className="pyqs-and-mock">PYPs and Mock FLTs</b>
        </div>
        <div className="features1">
          <div className="frame-div">
            <div className="div3">•</div>
            <div className="access-100-pyqs">2010-2024 PYPs with solutions</div>
          </div>
          <div className="parent1">
            <div className="div3">•</div>
            <div className="access-100-pyqs">7 Precise Mock FLTs</div>
          </div>
          <div className="parent1">
            <div className="div3">•</div>
            <div className="access-100-pyqs">Real exam-like interface </div>
          </div>
          {/* <div className="parent1">
            <div className="div3">•</div>
            <div className="access-100-pyqs">
              Get detailed solutions for all the questions
            </div>
          </div> */}
          <button
            onClick={() => handleClickBtn()}
            className="app-btn-1"
            style={{ textWrap: "nowrap" }}
          >
            Attempt PYPs /Mocks
          </button>
        </div>
      </div>
    </div>
  );
};

Section1.propTypes = {
  className: PropTypes.string,
};

export default Section1;
