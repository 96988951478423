import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
// import { ParticlesJS } from "./Particles";
import { useNavigate } from "react-router-dom";

import Hero from "./Hero";
import SectionOne from "./SectionOne";
import SectionTwo from "./SectionTwo";
import SectionThree from "./SectionThree";
import Testimonials from "./Testimonials";
import SectionFour from "./SectionFour";
import SectionFive from "./SectionFive";
import SectionSix from "./SectionSix";
import SectionSeven from "./SectionSeven";

import Footer from "../footer/Footer";

import AOS from "aos";

import { getFbAuth } from "../../context/FirebaseContext";

import "./landingpage.css";
import DownloadTheApp from "./DownloadTheApp";
import SectionEight from "./SectionEight";
import SectionNine from "./SectionNine";
import SectionTen from "./SectionTen";
import SectionEleven from "./SectionEleven";
import { getAnalytics, setUserProperties } from "firebase/analytics";
import ScrollParallex from "./ScrollParallex";
import SectionExtra from "./SectionExtra";
import Examcheckout from "./Examcheckout";
import axios from "axios";
import { loggedInUserCheck } from "../signIn/SignIn";
import useSigninPop from "../../redux/control/useSignInPop";
import SSCLandingPage from "./SSCLandingPage";
import SSCLandingPageMain from "./SSCLAndingPageMain";
// import Waste from "./waste";

export const examPageVisible =
  window.location.host === "www.niopractice.com" ||
  window.location.host === "niopractice.com";
const saturatedGovernmentExam = ["ibps", "ssc", "rrb"];

export const highSchool =
  window.location.host?.toLowerCase() === "highschool.niopractice.com";

function Landingpage({
  subjectualData,
  user,
  utmCompaignD,
  setFirstlogin,
  firstlogin,
}) {
  const { currentSigninPopup, setloginPopVisible } = useSigninPop();

  const navigate = useNavigate();

  const authUser = getFbAuth().currentUser;
  const [visibility, setVisibility] = useState(true);

  const [isButtonVisible, setIsButtonVisible] = useState(false);

  function handleClickStartPracticising() {
    // navigate("/chapter-selection");
    // if (user && user != '"undefined"') {
    //   navigate("/dashboard");
    // } else {
    //   navigate("/sign-in");
    // }

    if (loggedInUserCheck(user)) {
      if (subjectualData?.navbar?.includes("dashboard")) {
        navigate("/dashboard", { state: { UserID: user } });
      } else {
        navigate(
          `/${
            subjectualData?.navbar?.includes("testSeries")
              ? "testSeries/custom-test/timertest"
              : subjectualData?.navbar[0]
          }`,
          { state: { UserID: user } }
        );
      }
    } else {
      if (localStorage.getItem("UserID")) {
        if (subjectualData?.navbar?.includes("dashboard")) {
          navigate("/dashboard", {
            state: { UserID: localStorage.getItem("UserID") },
          });
        } else {
          navigate(
            `/${
              subjectualData?.navbar?.includes("testSeries")
                ? "testSeries/custom-test/timertest"
                : subjectualData?.navbar[0]
            }`,
            {
              state: { UserID: localStorage.getItem("UserID") },
            }
          );
        }
      } else {
        setloginPopVisible(true);
      }

      // navigate(
      //   "/chapter-selection" +
      //     "?&" +
      //     subjectualData?.DropSubjects[0] +
      //     "&Test=yes",
      //   { state: { UserID: user } }
      // );
    }
    // console.log(authUser)
  }

  function handleNavigate() {
    if (
      subjectualData?.navbar.length &&
      loggedInUserCheck(user) &&
      subjectualData?.navbar?.includes("testSeries")
    ) {
      navigate("/testSeries/custom-test/timertest");
    } else if (subjectualData?.navbar.length && loggedInUserCheck(user)) {
      navigate("/testSeries");
    } else {
      setloginPopVisible(true);
    }
  }

  // console.log(subjectualData?.navbar?.includes("dashboard"));
  function handleClickLogin() {
    // navigate("/chapter-selection");
    if (user && user != '"undefined"') {
      navigate("/dashboard");
    } else {
      navigate("/sign-in", { state: { defaultLogin: true } });
    }
    // console.log(authUser)
  }
  function handleShiftLogin() {
    // navigate("/chapter-selection");
    // if (user && user != '"undefined"') {
    if (subjectualData?.navbar.length && loggedInUserCheck(user)) {
      if (subjectualData?.navbar?.includes("dashboard")) {
        navigate("/dashboard");
      } else if (
        subjectualData?.navbar?.includes("testSeries", {
          state: { UserID: user },
        })
      ) {
        navigate("/testSeries/custom-test/timertest");
      }
    }
    // }
    // console.log(authUser)
  }
  useEffect(() => {
    if (firstlogin) {
      handleShiftLogin();
    }
    if (loggedInUserCheck(user)) {
      setFirstlogin(false);
    } else {
      setFirstlogin(true);
    }
  }, [user]);
  useEffect(() => {
    // console.log(user)
    let uidTest = user
      ? user
      : Math.floor((10000000 + Math.random()) * 0x10000)
          .toString(32)
          .substring(1);
    if (uidTest != '"undefined"' && uidTest) {
      // const uidTests = Math.floor((10000000000000 + Math.random()) * 0x100000)
      //     .toString(32)
      //     .substring(1)
      // uidTest = "New User " + uidTests

      const analytics = getAnalytics();
      setUserProperties(analytics, { user_id: uidTest });
    }
    // console.log(uidTest)
    // const userid = auth.currentUser?.phoneNumber
    //     ? auth.currentUser.phoneNumber?.substring(
    //         1,
    //         auth.currentUser.phoneNumber?.length
    //     )
    //     : '"' + auth.currentUser?.email + '"';
    // console.log(uidTest, auth.currentUser)
    // const analytics = getAnalytics();
    // setUserProperties(analytics, { user_id: uidTest });
  }, [window.location.pathname, user]);
  useEffect(() => {
    AOS.init({ duration: 2000, mirror: false, once: true });
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      // Get the vertical scroll position
      const scrollY = window.scrollY || window.pageYOffset;
      // Get the position and height of the button
      // Check if the button is visible on the screen
      if (scrollY < 312) {
        setIsButtonVisible(true);
      } else {
        setIsButtonVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isButtonVisible, setIsButtonVisible]);
  // console.log(subjectualData);
  return (
    <>
      <Helmet>
        <title> Niopractice - Upskill for your exam using AI</title>
        <meta
          name="Description"
          content={
            window?.location?.host?.toLowerCase()?.includes("gate")
              ? "The GATE CSE Test Series offers a structured and efficient way to prepare for the GATE exam. It includes chapter-wise tests focusing on individual topics like Algorithms, Data Structures, Operating Systems, and more, allowing you to master each concept thoroughly. Topic-wise tests combine related chapters, helping you understand the connections between various subjects like Data Structures and Networking.For quick practice, the series offers 10–15 minute sessions for fast, focused revision, ideal for daily practice or last-minute preparation. Revision questions reinforce key concepts, formulas, and theories to keep essential knowledge fresh. The time-limited practice tests help simulate exam conditions, enhancing speed and accuracy. With guided tests and instant feedback, you receive detailed solutions and step-by-step explanations, helping you learn from mistakes and improve. Chapter tests with guidance break down complex topics into manageable sections for more efficient learning. This comprehensive test series ensures focused, effective preparation, enabling you to maximize your performance in less time."
              : "Quick Practice with Niopractice! AI-powered software for quick learning and chapter mastery. Boost speed and ace your exams!"
          }
        />
      </Helmet>

      {/* {window.innerWidth > 600 && <ParticlesJS />} */}

      <div className="landingpage">
        {window.location.host?.toLowerCase()?.includes("ssc") ||
        window.location.host?.toLowerCase()?.includes("ibps") ||
        window.location.host?.toLowerCase()?.includes("rrb") ||
        window.location.host?.toLowerCase()?.includes("local") ||
        window.location.host?.toLowerCase()?.includes("gate") ? (
          window.location.host?.toLowerCase()?.includes("gate") ? (
            <SSCLandingPage
              handleNavigate={handleNavigate}
              subjectualData={subjectualData}
            />
          ) : (
            <SSCLandingPageMain
              handleNavigate={handleNavigate}
              subjectualData={subjectualData}
            />
          )
        ) : (
          <>
            {examPageVisible ? (
              <Examcheckout
                handleClickStartPracticising={handleClickStartPracticising}
                handleClickLogin={handleClickLogin}
                authUser={authUser}
                subjectualData={subjectualData}
                utmCompaignD={utmCompaignD}
              />
            ) : null}

            {/* {visibility && <DownloadTheApp visibility={visibility} setVisibility={setVisibility} />} */}
            {!examPageVisible ? (
              <>
                <Hero
                  highSchool={highSchool}
                  handleShiftLogin={handleShiftLogin}
                  user={user}
                  handleClickStartPracticising={handleClickStartPracticising}
                  handleClickLogin={handleClickLogin}
                  authUser={authUser}
                  subjectualData={subjectualData}
                />
                {/* <Waste /> */}

                {/* regultaion to effective practice is costly section  */}
                <SectionSeven />
                {!highSchool ? <ScrollParallex /> : null}

                <SectionEight />
                {!highSchool ? <SectionExtra /> : null}
                <SectionNine />
                {/* benefit of our app - 1 */}
                {/* <SectionOne /> */}

                {/* benefit of our app - 2 */}
                {/* <SectionTwo /> */}

                {/* benefit of our app - 2 */}
                {/* <SectionThree /> */}

                {/* stats */}
                <SectionFour />
                <SectionTen />

                {/* <Testimonials /> */}

                {/* app ads */}
                <SectionFive
                  handleClickStartPracticising={handleClickStartPracticising}
                  authUser={authUser}
                />

                {/* about nioclass founder */}
                {/* <SectionSix /> */}

                <SectionEleven />

                <Footer />
              </>
            ) : null}
          </>
        )}
      </div>
    </>
  );
}

export default Landingpage;
