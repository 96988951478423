import PropTypes from "prop-types";
import "./Section.css";
import { useNavigate } from "react-router-dom";

const Section = ({ className = "" }) => {
  const navigation = useNavigate();

  const handleClickBtn = () => {
    navigation("/testSeries/custom-test/guidedtest");
  };
  return (
    <div className={`custom-test ${className}`}>
      <div className="custom-test-features">
        <div className="heading">
          <img
            className="custom-test-icon"
            alt="timer test"
            loading="lazy"
            src={"/custom-test-icon.svg"}
          />
          <div className="create-your-own-container">
            <b>{`“Learn Mode” Practice`}</b>
            {/* <i className="with-10000-questions">{`with 10,000+ Questions `}</i> */}
          </div>
        </div>
        <div className="features">
          <div className="parent">
            <div className="div">•</div>
            <div className="create-exam-wise-custom">
              Targeted practice for faster learning
            </div>
          </div>
          <div className="group">
            <div className="div">•</div>
            <div className="evaluate-your-strengths">
              View solutions immediately after every question
            </div>
          </div>
          <div className="group">
            <div className="div">•</div>
            <div className="evaluate-your-strengths">
              Clear doubts immediately
            </div>
          </div>
          <button
            onClick={() => handleClickBtn()}
            className="app-btn-1"
            style={{ textWrap: "nowrap" }}
          >
            Start Learn Mode
          </button>
        </div>
      </div>
      <div className="custom-test-image">
        <img
          className="custom-test-image1"
          alt=""
          src={
            window.location.host?.toLowerCase()?.includes("ssc")
              ? "/custom-test-image@2x.png"
              : window.location.host?.toLowerCase()?.includes("ibps")
              ? "/ibps_custom_Test.png"
              : window.location.host?.toLowerCase()?.includes("gate")
              ? "/guidedTestGate.png"
              : "/rrb_custom_Test.png"
          }
        />
      </div>
    </div>
  );
};

Section.propTypes = {
  className: PropTypes.string,
};

export default Section;
