export function processSubject(subject) {
  // Separate the ‘_GATE’ suffix
  let baseSubject = subject.replaceAll("_GATE", "")?.trim();
  baseSubject = baseSubject.replaceAll("_", "")?.trim();
  // Add spaces before each uppercase letter, except the first one
  let formattedSubject = baseSubject.replace(/([A-Z])/g, " $1")?.trim();
  if (formattedSubject == "D B M S") {
    formattedSubject = "DBMS";
  }
  if (formattedSubject === "Theoryof Computation") {
    formattedSubject = "Theory of Computation";
  }
  //   console.log(formattedSubject);
  return formattedSubject;
}
