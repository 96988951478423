import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

import useCurrentUser from "../../../redux/control/useCurrentUser";
import useCurrentChapter from "../../../redux/control/useCurrentChapter";

import { FirebaseAuthContext } from "../../../context/FirebaseAuthContext";

import useLocalUserData from "../../../localStorage/useLocalUserData";
import dataCountry from "../data/countryData";
import { highSchool } from "../../landingpage/Landingpage";
import { getAnalytics, logEvent } from "firebase/analytics";
import ReactPixel from "react-facebook-pixel";
import RedditPixel from "react-reddit-pixel";



// const QuoraPixel = () => {
//   useEffect(() => {
//     // Replace 'YOUR_PIXEL_ID' with your actual Quora Pixel ID
//     window.qp = window.qp || [];
//     window.qp.push(['init', '9da8ea9ce9b04155bf7b5baa6d9eb989']);
//     window.qp.push(['track', 'PageView']); // Track page views

//     // Function to track custom events
//     const trackEvent = (eventName) => {
//       window.qp.push(['track', eventName]);
//     };

//     // Example of tracking a signup success event
//     const handleSignupSuccess = () => {
//       trackEvent('signup_success'); // Track signup success event
//     };

//     // Call this function when a signup is successful
//     // For example, you might call this in your signup form submission handler

//   }, []);

//   return null; // This component does not render anything
// };

// import createLocalUserData from "../../components/utils/useLocalUserData";

const initialState = {
  otp: "",
  name: "",
};

const useVerifyOTpUseLogic = () => {
  const { resultAuth } = useContext(FirebaseAuthContext);

  const { currentChapter } = useCurrentChapter();
  const { setCurrentUser } = useCurrentUser();
  // console.log(highSchool);

  const navigate = useNavigate();
  const location = useLocation();
  // console.log();

  const { createLocalUserData } = useLocalUserData();
  const [selectedCountry, setSelectedCountry] = useState(
    highSchool ? dataCountry[1] : dataCountry[0]
  );
  const [subjectAll, setSubjectAll] = useState({});

  const [userExistsAlready, setUserExistAlready] = useState();
  const [refreluserExistsAlready, refrelsetUserExistAlready] = useState(false);
  const [referalbyUserId, setRefrelByUserId] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [autoSubmit, setAutoSubmit] = useState(true);
  const [DeviceIDModal, setDeviceIDModal] = useState(false);
  const [info, setInfo] = useState(initialState);
  const [isLoadingOTP, setisLoadingOTP] = useState(false);
  const [userInfor, setUserInfor] = useState([]);
  const [openNameModal, setOpenNameModal] = useState(true);
  const [otpCorrection, setOtpCorrection] = useState(false);
  const [utmCompaignD, setUtmCompaignD] = useState(null);
  const [subjectualData, setSubjectualData] = useState(null);

  // const referred_by = location?.state?.referred_by
  //     ? location?.state?.referred_by
  //     : undefined;

  const chapId = location?.state?.ChapterID;

  const replacePlusPhoneNUmber = "+" + phoneNumber;

  let isVeryPhoneOTP = false;

  useEffect(() => {}, [isVeryPhoneOTP, info]);
  if (!info) {
    isVeryPhoneOTP = info.otp?.length >= 6 && info?.name?.length > 1;
  } else {
    isVeryPhoneOTP = info.otp?.length >= 6;
  }
  let addninetyone = (selectedCountry.code + phoneNumber).slice(1);
  // console.log(addninetyone)




  // useEffect(() => {
  //   // Replace 'YOUR_PIXEL_ID' with your actual Quora Pixel ID
  //   window.qp = window.qp || [];
  //   window.qp.push(['init', '9da8ea9ce9b04155bf7b5baa6d9eb989']);
  //   window.qp.push(['track', 'PageView']); // Track page views

  //   // Function to track custom events
  //   const trackEvent = (eventName) => {
  //     window.qp.push(['track', eventName]);
  //   };

  //   // Example of tracking a signup success event
  //   const handleSignupSuccess = () => {
  //     trackEvent('signup_success'); // Track signup success event
  //   };

  //   // Call this function when a signup is successful
  //   // For example, you might call this in your signup form submission handler

  // }, []);




  useEffect(() => {
    async function checkUserExist() {
      try {
        await axios
          .get(
            "https://uhuu9h6pef.execute-api.ap-northeast-1.amazonaws.com/getUserProfileDetails/getuserprofiledetails?UserID=" +
              addninetyone,
            {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
              },
            }
          )
          .then((resp) => {
            const data = resp.data;
            // console.log(data[0] ? true : false)
            // setUserExistAlready(data ? true : false);
            setUserExistAlready(data[0] ? true : false);

            //add user credential to the redux user store - data[0]
            setCurrentUser(data[0]);
            setUserInfor(data[0] ? [data[0]] : []);
            // setCurrentUser(data)

            //add user credential to Local Storage - data[0]
            createLocalUserData(data[0]);
            // createLocalUserData(data)

            return data[0] ? true : false;
          })
          .catch((err) => console.log(err));
      } catch (e) {
        console.log("caught err" + e.message);
        return Promise.reject(e);
      }
    }

    checkUserExist();
  }, [addninetyone]);
  // console.log(userInfor)
  // console.log(utmCompaignD);

  async function refercheckUserExist(refferByRefrel) {
    try {
      await axios
        .get(
          "https://uhuu9h6pef.execute-api.ap-northeast-1.amazonaws.com/getUserProfileDetails/getuserprofiledetails?UserID=" +
            refferByRefrel,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((resp) => {
          const data = resp.data;
          refrelsetUserExistAlready(data[0] ? true : false);
          return data[0] ? true : false;
        })
        .catch((err) => console.log(err));
    } catch (e) {
      console.log("caught err" + e.message);
      return Promise.reject(e);
    }
  }
  async function updateCreditPoints(referalbyUserId) {
    try {
      await axios.get(
        "https://lkbe8ay3y7.execute-api.ap-northeast-1.amazonaws.com/updateUserCredits/updateusercredits?UserID=" +
          referalbyUserId +
          "&CreditPoints=1",
        {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        }
      );
    } catch (e) {
      console.log("caught err" + e.message);
    }
  }
  //console.log(info, "hii", info.name)
  //console.log(replacePlusPhoneNUmber, "91" + phoneNumber)
  async function handleNameUpdate() {
    await axios.get(
      "https://1edof059ii.execute-api.ap-northeast-1.amazonaws.com/setUserName/setusername?UserID=" +
        addninetyone +
        "&Name=" +
        info.name,
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
  }

  async function addUserDetailsToUserProfile(myusrid, utmCompaignD) {
    try {
      await axios.get(
        "https://o33ra6381d.execute-api.ap-northeast-1.amazonaws.com/addNewUserIDToUserProfile/addnewuseridtouserprofile?UserID=" +
          myusrid +
          "&RefferedBy=" +
          (referalbyUserId ? referalbyUserId : "None") +
          "&utmCompaignD=" +
          (localStorage.getItem("UtmCampaign")?.length > 2
            ? localStorage.getItem("UtmCampaign")
            : utmCompaignD) +
          "&Platform=" +
          window.location.url,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      //console.log(info, "hii", info.name)

      await axios.get(
        "https://1edof059ii.execute-api.ap-northeast-1.amazonaws.com/setUserName/setusername?UserID=" +
          myusrid +
          "&Name=" +
          info.name,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      //console.log(myusrid)
      await axios.get(
        "https://ni7u05base.execute-api.ap-northeast-1.amazonaws.com/setPhoneNumber/setphonenumber?UserID=" +
          myusrid +
          "&PhoneNumber=" +
          myusrid,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      // add user to redux -

      // try {
      //     axios.get(
      //         "https://falpuarwj7.execute-api.ap-northeast-1.amazonaws.com/getChapterStartQuestionID/getchapterstartquestionid?ChapterID=" +
      //         chapId,
      //         {
      //             headers: {
      //                 "Content-Type":
      //                     "application/x-www-form-urlencoded",
      //             },
      //         }
      //     )
      //         .then((resp) => {

      //             let adata;
      //             if (resp.data?.length !== 0) {
      //                 adata = resp.data[0];
      //             } else {
      //                 adata = { QuestionID: chapId + "_1" };
      //             }

      //             axios
      //                 .get(
      //                     "https://josa0h5uvb.execute-api.ap-northeast-1.amazonaws.com/InitializeChapterStatus/initializechapterstatus?UserID=" +
      //                     myusrid +
      //                     "&ChapterID=" +
      //                     chapId +
      //                     "&QuestionID=" +
      //                     adata.QuestionID,
      //                     {
      //                         headers: {
      //                             "Content-Type":
      //                                 "application/x-www-form-urlencoded",
      //                         },
      //                     }
      //                 )
      //                 .then(() => {
      //                     axios
      //                         .get(
      //                             "https://f1nohln2og.execute-api.ap-northeast-1.amazonaws.com/getNewDeck/getnewdeck?ChapterID=" +
      //                             chapId +
      //                             "&QuestionID=" +
      //                             adata.QuestionID,
      //                             {
      //                                 headers: {
      //                                     "Content-Type":
      //                                         "application/x-www-form-urlencoded",
      //                                 },
      //                             }
      //                         )
      //                         .then((resp) => {

      //                             let adata;
      //                             if (resp.data?.length !== 0) {
      //                                 adata = resp.data;
      //                             } else {
      //                                 adata = [
      //                                     {
      //                                         QuestionID: chapId + "_1",
      //                                     },
      //                                 ];
      //                             }

      //                             let myurlstr =
      //                                 "https://donvtn5h2b.execute-api.ap-northeast-1." +
      //                                 "amazonaws.com/addDeckHistoryRecord/adddeckhistoryrecord?" +
      //                                 "UserID=" +
      //                                 myusrid +
      //                                 "&DeckID=" +
      //                                 myusrid +
      //                                 "_" +
      //                                 new Date()
      //                                     .toISOString()
      //                                     .slice(0, 19)
      //                                     .replace("T", " ") +
      //                                 "&ChapterID=" +
      //                                 chapId +
      //                                 "&DeckStartTime=" +
      //                                 new Date()
      //                                     .toISOString()
      //                                     .slice(0, 19)
      //                                     .replace("T", " ") +
      //                                 "&DeckQuestionIDs=" +
      //                                 adata
      //                                     .map((x) => x.QuestionID)
      //                                     .join(",") +
      //                                 "&LastQuestionIDAttempted=" +
      //                                 adata[0].QuestionID +
      //                                 "&DeckCompleted=0&DeckExitTime=" +
      //                                 new Date()
      //                                     .toISOString()
      //                                     .slice(0, 19)
      //                                     .replace("T", " ") +
      //                                 "&PercentageChapterCompletionScore=0.0";

      //                             axios
      //                                 .get(myurlstr, {
      //                                     headers: {
      //                                         "Content-Type":
      //                                             "application/x-www-form-urlencoded",
      //                                     },
      //                                 })
      //                                 .then(() => {
      //                                     //console.log(chapId,adata)

      //                                     // navigate("/chapter-practice", {
      //                                     //     state: {
      //                                     //         ChapterID: chapId,
      //                                     //         QuestionIDs: adata,
      //                                     //     },
      //                                     //     // });
      //                                     // });
      //                                 }).catch((err) =>
      //                                     console.log(err)
      //                                 ); // added by kunal
      //                             // });
      //                         })
      //                         .catch((err) =>console.log(err));
      //                     // });
      //                 })
      //                 .catch((err) => {
      //                     console.log(err);
      //                 });
      //             // });
      //         })
      //         .catch((err) =>console.log(err));
      // } catch (e) {
      //     console.log("caught err" + e.message);
      // }
    } catch (e) {
      console.log("caught err" + e.message);
    }
  }
  function initializeChapterAndStartDeck(imychap) {
    // get chapter start question id
    // initialize chapter status record with
    //console.log(imychap)
    let mychap = imychap.selectedChapterId;
    //console.log("entered");
    //console.log(mychap);
    try {
      axios
        .get(
          "https://falpuarwj7.execute-api.ap-northeast-1.amazonaws.com/getChapterStartQuestionID/getchapterstartquestionid?ChapterID=" +
            mychap.ChapterID,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((resp) => {
          // resp.data.then((data) => {
          setisLoadingOTP(true);
          let adata = resp.data[0];
          //console.log(resp);
          //console.log(
          //     "============================================================>",
          //     adata, resp
          // );
          // resp.data.then((data) => {
          // let mdata = data[0];
          //notifySubscribeSuccessMessage();
          //unwanted#
          //console.log(mychap, adata)
          if (mychap && adata) {
            axios
              .get(
                "https://f1nohln2og.execute-api.ap-northeast-1.amazonaws.com/getNewDeck/getnewdeck?ChapterID=" +
                  mychap.ChapterID +
                  "&QuestionID=" +
                  adata.QuestionID,
                {
                  headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                  },
                }
              )
              .then((resp) => {
                // resp.data.then((data) => {
                let adata = resp.data;
                // add deck history record here
                //console.log("8447010684 " + adata);
                setisLoadingOTP(true);
                navigate("/chapter-practice", {
                  state: {
                    ChapterID: mychap.ChapterID,
                    QuestionIDs: adata,
                  },
                  // });
                });
              })
              .catch((err) => {
                console.log(err);
              });
          } // added by kunal
          // });

          // });
        });
      // });
    } catch (e) {
      console.log("caught err" + e.message);
    }
  }
  // console.log()
  useEffect(() => {
    refercheckUserExist(referalbyUserId);
  }, [referalbyUserId, refreluserExistsAlready, userExistsAlready]);

  const onHandleChangeInputOtp = (e, type) => {
    const val = e.target.value;
    if (/\d/.test(val)) return;

    const capitalized = val
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

    setInfo({ ...info, [type]: capitalized });
  };
  // console.log(info, phoneNumber,)
  // console.log(subjectAll)
  const onVerifyOtp = async () => {
    if (isLoadingOTP) return;
    setisLoadingOTP(true);
    try {
      await resultAuth
        .confirm(info.otp)
        .then(async () => {
          // //console.log("hii")
          setOtpCorrection(true);
          if (referalbyUserId) {
            if (!userExistsAlready) {
              updateCreditPoints(referalbyUserId);
            }
          }

          // add user to redux

          // console.log(userExistsAlready)

          const locator =
            location.pathname?.toLowerCase().includes("quicktest") ||
            location.pathname?.toLowerCase().includes("submittest");

          setTimeout(() => {
            if (localStorage.getItem("UserID")) {
              axios.get(
                "https://yec5cxedkh.execute-api.ap-northeast-1.amazonaws.com/updateUserIDwithTables?NewUserID=" +
                  addninetyone +
                  "&OldUserID=" +
                  localStorage.getItem("UserID")
              );
            }

            if (userExistsAlready) {
              if (locator) {
              } else {
                if(window.localStorage?.getItem("navigationNavbar")){
                  navigate(window.localStorage?.getItem("navigationNavbar"),{ state: { UserID: localStorage.getItem("UserID") } })
                  window.localStorage?.removeItem("navigationNavbar")
                }
                else if (
                  window.location.pathname.includes("website") ||
                  location.pathname.includes("contact-us") ||
                  location.pathname.includes("practiceHub") ||
                  window.location.pathname.includes("sign-in")
                ) {
                  if (location?.state?.pricing) {
                    navigate("/website", { state: { pricing: true } });
                  } else {
                    // navigate("/chapter-selection",{state:{Chapter:subjectAll?.DropSubjects[0]}});

                    if (subjectualData?.navbar?.includes("chapters")) {
                      navigate("/dashboard", {
                        state: { UserID: addninetyone },
                      });
                    } else {
                      navigate(
                        `/${
                          subjectualData?.navbar?.includes("testSeries")
                            ? "testSeries/custom-test/timertest"
                            : subjectualData?.navbar[0]
                        }`,
                        {
                          state: { UserID: addninetyone },
                        }
                      );
                    }
                  }
                }
              }
              // //console.log("User already exist");
            } else {
              //console.log("hi");
              // addUserDetailsToUserProfile("91" + phoneNumber);
              addUserDetailsToUserProfile(
                addninetyone,
                localStorage.getItem("UtmCampaign")?.length > 2
                  ? localStorage.getItem("UtmCampaign")
                  : utmCompaignD
              );
              const analytics = getAnalytics();
              if(window?.location?.host?.includes('gate')){
              logEvent(analytics, "signup_success", {
                method: "phone", // Optional: Add additional parameters if needed
              });
              logEvent(analytics, "signup_success_quora", {
                method: "phone", // Optional: Add additional parameters if needed
              });
            }
              // if (window.reddit) {
              //   window.reddit("track", "signup_success", {
              //     event_name: "signup_success",
              //     additional_info: "signup_success",
              //   });
              // }
              if(window?.location?.host?.includes('gate')){
              ReactPixel.track("signup_success", {
                method: "signup_success",
              });
            }
              // RedditPixel.track("signup_success");
              // if (window.quora) {
              //   window.quora.push(['track', 'PageView']);
              //   window.quora.push(['track', 'signup_success', { customProperty: 'signup_success' }]);

              // }
              // if (window.rdt) {
              //   window.rdt('track', 'signup_success'); // Track signup success event
              // }
              // window.qp?.push(['track', 'signup_success']);
              // console.log("added")
              // initializeChapterAndStartDeck(currentChapter);

              if (locator) {
              } else {
                if(window.localStorage?.getItem("navigationNavbar")){
                  navigate(window.localStorage?.getItem("navigationNavbar"),{ state: { UserID: localStorage.getItem("UserID") } })
                  window.localStorage?.removeItem("navigationNavbar")
                }
                else if (
                  window.location.pathname.includes("website") ||
                  location.pathname.includes("contact-us") ||
                  location.pathname.includes("practiceHub") ||
                  window.location.pathname.includes("sign-in")
                ) {
                  if (location?.state?.pricing) {
                    navigate("/website", { state: { pricing: true } });
                  } else {
                    let user_id = addninetyone;

                    if (subjectualData?.navbar?.includes("chapters")) {
                      navigate(
                        "/chapter-selection" +
                          "?&" +
                          subjectualData?.DropSubjects[0] +
                          "&Test=yes",
                        { state: { UserID: user_id } }
                      );
                    } else {
                      navigate(
                        `/${
                          subjectualData?.navbar?.includes("testSeries")
                            ? "testSeries/custom-test/timertest"
                            : subjectualData?.navbar[0]
                        }`,
                        {
                          state: { UserID: user_id },
                        }
                      );
                    }
                  }
                }
              }
            }
            localStorage.setItem("UserID", addninetyone);
          }, [1000]);
          // setting currentChapter
        })
        .catch((error) => {
          if (error.code === "auth/invalid-verification-code") {
            alert("Incorrect OTP! \n please enter correct OTP");
          }

          console.log(error);
        });
    } catch (error) {
      console.log(error.code);
      setAutoSubmit(false);
      alert("Invalid OTP!!! or OTP expired!!!");
    } finally {
      setTimeout(() => {
        setisLoadingOTP(false);
      }, [1000]);
    }
  };

  return {
    onHandleChangeInputOtp,
    isLoadingOTP,
    userExistsAlready,
    setSelectedCountry,
    selectedCountry,
    info,
    onVerifyOtp,
    setPhoneNumber,
    setRefrelByUserId,
    isVeryPhoneOTP,
    refreluserExistsAlready,
    autoSubmit,
    setOpenNameModal,
    otpCorrection,
    openNameModal,
    setAutoSubmit,
    userInfor,
    handleNameUpdate,
    setSubjectAll,
    setUtmCompaignD,
    setSubjectualData,
  };
};

export default useVerifyOTpUseLogic;
