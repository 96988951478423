import PropTypes from "prop-types";
import "./Section1.css";
import { useNavigate } from "react-router-dom";

const Section0 = ({ className = "" }) => {
  const navigation = useNavigate();

  const handleClickBtn = () => {
    navigation("/testSeries/custom-test/timertest");
  };

  return (
    <div className={`pyq-and-mocks ${className}`}>
      <div className="pyq-mock-tests-image">
        <img
          className="pyq-mock-tests-image1"
          alt="Custom test"
          src={
            window.location.host?.toLowerCase()?.includes("ssc")
              ? "/pyqmock-tests-image@2x.png"
              : window.location.host?.toLowerCase()?.includes("ibps")
              ? "/IBPS_Pyq_mock.png"
              : window.location.host?.toLowerCase()?.includes("gate")
              ? "/customTestGate.png"
              : "/customTestGate.png"
          }
        />
      </div>
      <div className="pyqs-mocks-features">
        <div className="heading1">
          <img
            className="custom-test-icon"
            alt=""
            src={"/custom-test-icon.svg"}
          />
          <b className="pyqs-and-mock">
            Target Weak Areas using
            <br /> Custom Tests
          </b>
        </div>
        <div className="features1">
          <div className="frame-div">
            <div className="div3">•</div>
            <div className="access-100-pyqs">
              Zero-in on what you need to practice
            </div>
          </div>
          <div className="parent1">
            <div className="div3">•</div>
            <div className="access-100-pyqs">
              Set your chapters, time, and number of questions
            </div>
          </div>
          <div className="parent1">
            <div className="div3">•</div>
            <div className="access-100-pyqs">
              Get in-depth analysis and solutions
            </div>
          </div>
          {/* <div className="parent1">
            <div className="div3">•</div>
            <div className="access-100-pyqs">Real Exam like interface</div>
          </div>
          <div className="parent1">
            <div className="div3">•</div>
            <div className="access-100-pyqs">
              Get detailed solutions for all the questions
            </div>
          </div> */}
          <button
            onClick={() => handleClickBtn()}
            className="app-btn-1"
            style={{textWrap:"nowrap"}}
          >
            Start Custom Test
          </button>
        </div>
      </div>
    </div>
  );
};

Section0.propTypes = {
  className: PropTypes.string,
};

export default Section0;
